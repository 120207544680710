import { lazy } from 'react';
import cashPickup from './cashPickup';
import cashBack from './cashBack';
import cancelTransaction from './cancelTransaction';
import modifyTransaction from './modifyTransaction';
import sendCash from './sendCash';
import deposit from './deposit';
import transactionsHistory from './transactionsHistory';
import commissions from './commissions';
import cashpoints from './cashpoints';
import cashpointHelpers from './cashpointHelpers';
import tellers from './tellers';
import cashPooling from './cashPooling';
import linkAccount from './linkAccount';
import linkBankAccount from './linkBankAccount';

export default {
  exact: false,
  name: 'Dashboard',
  protected: true,
  path: '/dashboard',
  component: lazy(() => import('containers/Dashboard')),
  children: [
    cashPickup,
    cashBack,
    cancelTransaction,
    modifyTransaction,
    sendCash,
    deposit,
    transactionsHistory,
    commissions,
    cashpoints,
    cashpointHelpers,
    tellers,
    cashPooling,
    linkAccount,
    linkBankAccount,
  ],
};
