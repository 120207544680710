import logo from 'assets/images/logo.png';

export default function getDefaultOperator(payload: Record<string, string>): Record<string, string | undefined> {
  return {
    ID: process.env.REACT_APP_SHORT_NAME,
    CountryCode: payload.CountryCode,
    Available: 'YES',
    OperatorID: process.env.REACT_APP_SHORT_NAME,
    ShortName: process.env.REACT_APP_SHORT_NAME,
    OperatorName: process.env.REACT_APP_NAME,
    Category: '0',
    TargetType: '0',
    ActionType: '1',
    ServiceRef: process.env.REACT_APP_SHORT_NAME,
    MinPayAmount: '0',
    MaxPayAmount: '0',
    Currency: '',
    Fields: '',
    ActionTypeName: 'TRANSFER',
    PrePaid: 'YES',
    AcceptVoucher: 'NO',
    BrandUnikID: '0',
    LogoNumber: '0',
    LogoName: '',
    AccountPattern: '',
    Logo: logo,
    Flag: '',
  };
}
