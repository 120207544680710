import {
  BANK_DEPOSIT_START,
  BANK_DEPOSIT_FAILURE,
  BANK_DEPOSIT_SUCCESS,
} from 'constants/action-types/transactions/bankDeposit';

export default (state, { type, payload }) => {
  switch (type) {
    case BANK_DEPOSIT_START:
      return {
        ...state,
        bankDeposit: {
          ...state.bankDeposit,
          data: {},
          loading: true,
          error: null,
          success: false,
        },
      };
    case BANK_DEPOSIT_FAILURE:
      return {
        ...state,
        bankDeposit: {
          ...state.bankDeposit,
          data: {},
          error: payload,
          loading: false,
          success: false,
        },
      };
    case BANK_DEPOSIT_SUCCESS:
      return {
        ...state,
        confirmCashpointTransfer: {
          ...state.confirmTransfer,
          data: {},
          error: null,
          loading: false,
          success: false,
        },
        bankDeposit: {
          ...state.bankDeposit,
          data: payload,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
