import ITransaction from 'types/initial-states/ITransaction';
import { ReduxActionType } from 'types';
import transactionInitialState from 'redux/initial-states/transaction';
import getOperationsCountries from './getOperationsCountries';
import confirmTransfer from './confirmTransfer';
import transferFunds from './transferFunds';
import confirmCashpointTransfer from './confirmCashpointTransfer';
import addMoney from './addMoney';
import bankDeposit from './bankDeposit';
import sendCash from './sendCash';
import checkSendCashTransfer from './checkSendCashTransfer';
import cashPickup from './cashPickup';
import cashBack from './cashBack';
import cancelTransaction from './cancelTransaction';
import modifyTransaction from './modifyTransaction';
import getTransactionsHistory from './getTransactionsHistory';
import getTransactionsGraphData from './getTransactionsGraphData';
import getCommissions from './getCommissions';
import getOperatorsList from './getOperatorsList';

export default (initialState: ITransaction, action: ReduxActionType): ITransaction => {
  const state = initialState || transactionInitialState;
  const actionToDispatch = action || {};
  return {
    ...state,
    ...getOperationsCountries(state, actionToDispatch),
    ...confirmTransfer(state, actionToDispatch),
    ...transferFunds(state, actionToDispatch),
    ...confirmCashpointTransfer(state, actionToDispatch),
    ...addMoney(state, actionToDispatch),
    ...bankDeposit(state, actionToDispatch),
    ...sendCash(state, actionToDispatch),
    ...checkSendCashTransfer(state, actionToDispatch),
    ...cashPickup(state, actionToDispatch),
    ...cashBack(state, actionToDispatch),
    ...cancelTransaction(state, actionToDispatch),
    ...modifyTransaction(state, actionToDispatch),
    ...getTransactionsHistory(state, actionToDispatch),
    ...getTransactionsGraphData(state, actionToDispatch),
    ...getCommissions(state, actionToDispatch),
    ...getOperatorsList(state, actionToDispatch),
  };
};
