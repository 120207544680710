export default {
  operatorsList: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: [],
  },
  getOperationsCountries: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: [],
  },
  confirmTransfer: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  transferFunds: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  confirmCashpointTransfer: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  addMoney: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  sendCash: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  checkSendCashTransfer: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  cashPickup: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  cashBack: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  cancelTransaction: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  modifyTransaction: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: {},
  },
  getTransactionsHistory: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: [],
    meta: {},
  },
  getTransactionsGraphData: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: [],
  },
  commissions: {
    error: null,
    loading: false,
    message: '',
    success: false,
    data: [],
    meta: {},
  },
};
