import ITransaction from 'types/initial-states/ITransaction';
import { ReduxActionType } from 'types';
import {
  GET_OPERATORS_LIST_START,
  GET_OPERATORS_LIST_FAILURE,
  GET_OPERATORS_LIST_SUCCESS,
} from 'constants/action-types/transactions/geOperatorsList';
import getDefaultOperator from 'helpers/getDefaultOperator';

export default (state: ITransaction, { type, payload }: ReduxActionType): ITransaction | null => {
  const defaultOperator =
    type === GET_OPERATORS_LIST_START ? getDefaultOperator(payload as Record<string, string>) : null;

  switch (type) {
    case GET_OPERATORS_LIST_START:
      return {
        ...state,
        operatorsList: {
          ...state.operatorsList,
          data: defaultOperator ? [defaultOperator] : [],
          loading: true,
          error: null,
          success: false,
        },
      };
    case GET_OPERATORS_LIST_FAILURE:
      return {
        ...state,
        operatorsList: {
          ...state.operatorsList,
          data: defaultOperator ? [defaultOperator] : [],
          error: payload,
          loading: false,
          success: false,
        },
      };
    case GET_OPERATORS_LIST_SUCCESS:
      return {
        ...state,
        operatorsList: {
          ...state.operatorsList,
          data: defaultOperator ? [defaultOperator, ...(payload as Array<Record<string, any>>)] : payload,
          error: null,
          loading: false,
          success: true,
        },
      };
    default:
      return null;
  }
};
